import React from 'react'
import Div from '../Div'
import AuthorWidget from '../Widget/AuthorWidget'
import RecentPost from '../Widget/RecentPost'
// import SearchWidget from '../Widget/SearchWidget'
import SideMenuWidget from '../Widget/SideMenuWidget'
import TagWidget from '../Widget/TagWidget'

export default function Sidebar() {
  const tagData = [
    {
      title: 'Web 3',
      url:'/blog/how-not-to-start-a-web3-biz'
    },
    {
      title: 'NFTs',
      url:'/blog/a-case-for-nfts'
    },
    {
      title: 'Blockchain',
      url:'/blog/a-case-for-data-engineers'
    },
  ]
  const archiveData = [
    {
      title: 'Archives', 
      url:'/blog'
    },
    {
      title: '01 Mar 2024',
      url:'/blog/a-case-for-nfts'
    },
    {
      title: '08 Feb 2024',
      url:'/blog/how-not-to-start-a-web3-biz'
    },
    {
      title: '15 Mar 2024',
      url:'/blog/a-case-for-data-engineers'
    },
    
  ]
  const categoryData = [
    {
      title: 'Blockchain',
      url:'/blog/a-case-for-data-engineers'
    },
    {
      title: 'Crptocurrency',
      url:'/blog/how-not-to-start-a-web3-biz'
    },
    {
      title: 'NFTs',
      url:'/blog/a-case-for-nfts'
    },
  ]
  const recentPostData = [
    {
      title: 'A case for NFTs...',
      thumb: '/images/nft_post_1.png',
      href: '/blog/a-case-for-nfts',
      date: '01 Mar 2024'
    },
    {
      title: 'How NOT to start...',
      thumb: '/images/how_not_2.png',
      href: '/blog/how-not-to-start-a-web3-biz',
      date: '08 Mar 2024'
    },
    {
      title: 'Data Engineers, an important...',
      thumb: '/images/data_engine_2.png',
      href: '/blog/a-case-for-data-engineers',
      date: '22 Mar 2024'
    },
    // {
    //   title: 'The important roles...',
    //   thumb: '/images/recent_post_4.jpeg',
    //   href: '/blog/important-roles-in-web3',
    //   date: '29 Mar 2024'
    // },
  ]
  return (
    <>
      <Div className="cs-sidebar_item">
        <AuthorWidget 
          src='/images/greg_author.png'
          name='Gregory Ajayi' 
          description='Data analyst, healthcare tech, occupation and health scientist, with a keen interest in blockchain data engineering and development.'
        />
      </Div>
      {/* <Div className="cs-sidebar_item">
        <SearchWidget title='Search'/>
      </Div> */}
      <Div className="cs-sidebar_item">
        <SideMenuWidget title='Categories' data={categoryData}/>
      </Div>
      <Div className="cs-sidebar_item">
        <RecentPost title='Archives' data={recentPostData}/>
      </Div>
      <Div className="cs-sidebar_item">
        <SideMenuWidget title='Archives' data={archiveData}/>
      </Div>
      <Div className="cs-sidebar_item">
        <TagWidget title='Tags' data={tagData}/>
      </Div>
    </>
  )
}
