import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb: '/images/kami.jpeg',
      testimonialText:
        "Always willing to go the extra mile. The team is project oriented and detailed in their delivery. That's why we chose Lagos Labs for our fan experience objectives at Loverrr International.",
      avatarName: 'KAMI',
      avatarDesignation: 'Loverrr International',
      ratings: '4',
    },
    {
      testimonialThumb: '/images/dc_talent.jpeg',
      testimonialText:
        'Over the years, the team has maintained their vision and focus on creating dApps for Afrobeat artists to earn and create for fans and themselves. The future is always now, and with Lagos Labs, their Design, Deploy, and Scale logic allows for immediate and future rewards.',
      avatarName: 'Camillo Doregos',
      avatarDesignation: 'DC Talent Agency',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/vector.jpeg',
      testimonialText:
        'Representation for us for una. Web 3 for our own.',
      avatarName: 'Vector',
      avatarDesignation: 'Grap Music',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/pype.jpeg',
      testimonialText:
        'I will be utilizing Lagos Labs for the 7star project for an alternative reach and a gateway to a new standard in the industry.',
      avatarName: 'General Pype',
      avatarDesignation: 'Pype',
      ratings: '5',
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
