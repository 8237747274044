import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import 'swiper/css';
import 'swiper/css/pagination';
import Hero6 from '../Hero/Hero6';
import Spacing from '../Spacing';
import Div from '../Div';
import Button from '../Button';
import SectionHeading from '../SectionHeading';


export default function PortfolioGalacticos() {
  pageTitle('Galacticos FC Chicago');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const showcaseData = [
    {
      title: '',
      imgUrl: '/images/galacticos_1.png',
      href: '',
    },
    {
      title: '',
      imgUrl: '/images/galacticos_4.png',
      href: '',
    },
    {
      title: '',
      imgUrl: '/images/galacticos_3.png',
      href: '',
    },
    {
      title: '',
      imgUrl: '/images/galacticos_2.png',
      href: '',
    },
  ];

  return (
    <>
      <Hero6
        showcaseData={showcaseData}
      />

      <Spacing lg='150' md='80' />
      <Div className="container">
        <Spacing lg='90' md='40' />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Creative Design"
              subtitle="Design"
            >
              <Spacing lg='40' md='20' />
              <p>The Galacticos Soccer Jersey is a developing concept of modern merch management where proceeds from each merch sale, goes into blockchain development and grassroot soccer development.</p>
              <Spacing lg='10' md='10' />
              <p>We partnered with a local soccer team that fosters youth development and a summer safe haven in sports. We designed and manufactured the jerseys for the team and made extra for community support.</p>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg='60' md='40' />
            <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Project Info -</h2>
            <Spacing lg='50' md='30' />
            <Div className="row">
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Category:</h3>
                <p className='cs-m0'>Creative Design</p>
                <Spacing lg='30' md='30' />
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Location:</h3>
                <p className='cs-m0'>Chicago</p>
                <Spacing lg='30' md='30' />
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Dated:</h3>
                <p className='cs-m0'>14-Mar-2023</p>
                <Spacing lg='30' md='30' />
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Client:</h3>
                <p className='cs-m0'>Galacticos FC Chicago</p>
                <Spacing lg='30' md='30' />
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg='65' md='10' />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button btnLink='/portfolio/bubbleboy' btnText='Prev Project' variant='cs-type1' />
          </Div>
          <Div>
            <Button btnLink='/portfolio/streamillonaire' btnText='Next Project' />
          </Div>
        </Div>
      </Div>
    </>
  );
}