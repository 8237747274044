import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function Litepaper() {
  pageTitle('Litepaper');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Litepaper"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Welcome to Lagos"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Willingness to participate in blockchain and web3 is at an all time high following the crypto ETFs progression of blockchain technology."
          subtitle="Why Now?"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <img
          src="/images/case_study_img_1.jpeg"
          alt="Thumb"
          className="w-100 cs-radius_15"
        />
        <Spacing lg="140" md="80" />
        <h2 className="cs-font_38 text-center">Gap Analysis</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0">  
        Since decentralized applications became popular in 2017, studies show that the current state of decentralized applications (dApp) 
        and usage of the most prominent dApps are either financial based, or used for art-related trades and transactions. In addition,
        we found that there is a general lack of web 3 and dApps for lifestyle activities (e.g. event ticketing, health activities and monitoring, e-commerce, 
        entertainment, sports etc.) which we believe are applications required to propel the next wave of daily use of web3 and blockchain. 
        Our research shows that the following are major hindrances to achieveing the above;
        <br/><br/>First, there is a general lack of understanding around application development using blockchain technology; 
        <br/>Second, representation is sparce in web3 and blockchain development across industries;
        <br/>Third, navigating the web3 development atmosphere for non-technical founders is expensive, sophisticated and difficult to implement;
                
        </p>
    
        <Spacing lg="65" md="45" />
        <Div className="row">
          <Div className="col-sm-6">
            <img
              src="/images/litepaper_1.png"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-sm-6">
            <img
              src="/images/litepaper_2.png"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
        <Spacing lg="125" md="55" />
      </Div>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_6">
        <Div className="cs-shape_2"></Div>
        <Div className="cs-shape_2"></Div>
        <Div className="container">
          <Spacing lg="145" md="80" />
          <h2 className="cs-font_38 text-center">Why that's an issue </h2>
          <Spacing lg="90" md="45" />
          <Div className="row align-items-center cs-column_reverse_lg">
            <Div className="col-lg-5">
              <h3 className="cs-font_30 cs-m0">Problem</h3>
              <Spacing lg="45" md="30" />
              <p className="cs-m0">
                Blockchain and decentralization is a representation of freedom
                when applied correctly, can liberate and challenge the status 
                quo of organizational management of data inferred applications. The 
                aforementioned hinderances not only restricts the core tenets 
                of decentralization, but undermines it at its core. Furthermore 
                it allows for the monopolization and capitalization by mainstream
                players, making development with the network cumbersome as a whole.  <br />
                <br />
                In addition, non-technical creators and founders are often left out of the pool of the financial
                liberty and economic contribution offered by decentralization and blockchain 
                technologies. Meanwhile, creators in the lifestyle industries where dApps and web 3 can 
                prove a game changer, are least implied during development of dApps.
              </p>
            </Div>
            <Div className="col-lg-6 offset-lg-1 text-center">
              <Div className="cs-portfolio_img_in cs-shine_hover_1 rounded-circle">
                <img
                  src="/images/litepaper_3.png"
                  alt="Case study"
                  className="w-100"
                />
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
          </Div>
          <Spacing lg="100" md="80" />
          <Div className="row align-items-center">
            <Div className="col-lg-6 text-center">
              <Div className="cs-portfolio_img_in cs-shine_hover_1 rounded-circle">
                <img
                  src="/images/litepaper_4.png"
                  alt="Case study"
                  className="w-100"
                />
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <h3 className="cs-font_30 cs-m0">Solution</h3>
              <Spacing lg="45" md="30" />
              <p className="cs-m0">
                We posit, building a dApp-centric software product line will 
                enable creators and developers like us, build faster go-to-market 
                products that address the hindrances mentioned earlier. <br />
                <br />
                We have identified 3 phases in this creative development process. 
                First - a collaborative <i>design</i> process which adopts the standard 
                UML practices for dApp and web3 architecture. Second - a <i>deployment</i> phase 
                that uses the design to form business use-cases per project or industry (e.g. 
                a music streaming dApp for artist or an event ticketing dApp for event 
                creators). Third - ensuring that the dApp is <i>scalable</i> and service oriented 
                to promote growth in the ever evolving field of software and application development.
              </p>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
        </Div>
        
      </Div>
      <Spacing lg="140" md="80" />
      {/* <Div className="container text-center">
        <Div className="row col-lg-10 offset-lg-1">
          <h2 className="cs-font_38 cs-m0">At its core, our consumers can be divvied into 3 main groups</h2>
          <Spacing lg="60" md="45" />
          <p className="cs-m0">
            <br/>
            Our<i> Core Users -</i> the daily consumers of lifestyle applications built on blockchain, <br/>
            The<i> super-users -</i> creators, brands and businesses implementing web3 to their processes; and <br/>
            The<i> Admins -</i> for insights and analytics provided via API interactions in building and utilizing dApps.            
          </p>
        </Div>
      </Div> */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Representation thats Why"
          subtitle="Why it matters"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <img
          src="/images/representation.png"
          alt="Thumb"
          className="w-100 cs-radius_15"
        />
        <Spacing lg="60" md="45" />
        <p className="cs-m0">
          Founders often don't look like us. Over 70% of founding teams are
          often white and male dominated with the core development teams 
          saturated with people of color. This disparity, while not our driving
          force, is a motivating factor to build dApps for us and for you. 
          Furthermore, we aim to be an inspiration for future founders and creators 
          seeking representation in the dApp development industry. Our approach is an intersection
          of inclusion, culture and technology. This is emphasized in our design phase, carefully 
          identifying the needs of the communities build for, and are deploying within.
          
        </p>
        <Spacing lg="140" md="80" />
        <h2 className="cs-font_38 text-center">Product Based Disruption</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0">
          To address the aforementioned issues facing development teams, and general use of blockchain products, 
          We are using a product-first approach building dApps for us and for all. Our iteration with key stakeholders in 
          music and entertainment, content creation, sports and fitness, food distribution and manufacturing has been 
          revealing for the needs of our communities. We have developed a software product line to enhance the build approach
          for consumers. Our aim is simple, to create faster go-to market products that meet people where they are (e.g. a seamless 
          approach towards active users that do not require new or separate wallet formation). This approach allows us build trust and engage with customers
          where they are.   
        </p>
        <Spacing lg="65" md="45" />
        <Div className="row">
          <Div className="col-sm-6">
            <img
              src="/images/litepaper_5.png"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-sm-6">
            <img
              src="/images/litepaper_6.png"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
        <Spacing lg="125" md="55" />
      </Div>
      <Div className="container text-center">
        <Div className="row col-lg-10 offset-lg-1">
          <h2 className="cs-font_38 cs-m0">Call to Action</h2>
          <Spacing lg="60" md="45" />
          <p className="cs-m0">
            Blockchain technology is an empowering invention meant for liberation from the status quo.
            With Bitcoin and crypto ETFs introducing new players in the conventional asset management industry, 
            we believe a consequence of that will include the understanding and use of blockchain networks in 
            daily product use cases. From A.I. neural networks to food and lifestyle applications, there is a plethora of 
            use-cases yet to be impacted by web3 and blockchain beyond financial transactions. That is why at Lagos Labs,
            we are developing decentralized applications that will compete with traditional applications in the industries 
            we have highlighted within.<br/> Join us as we build the future we deserve, where every participation is an 
            opportunity for growth and community development.

          </p>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Learn about our <br /><i>exciting</i> projects"
          btnText='Contact Us Today' 
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
