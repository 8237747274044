import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import TechPage from './components/Pages/TechPage';
import TeamPage from './components/Pages/TeamPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import PortfolioStreamillonaire from './components/Pages/ProtfolioStreamillonaire';
import PortfolioGalacticos from './components/Pages/PortfolioGalacticos';
import PortfolioLoverrr from './components/Pages/PortfolioLoverrr';
import PortfolioBubbleboy from './components/Pages/PortfolioBubbleboy';
import PortfolioCreative from './components/Pages/PortfolioCreative';
import PortfolioPasuma from './components/Pages/PortfolioPasuma';
import PortfolioMylarBaggies from './components/Pages/PortfolioMylarBaggies';
import BlogHowNot from './components/Pages/BlogHowNot';
import BlogNFTDetails from './components/Pages/BlogNFTDetails';
import BlogUtilityNftDetails from './components/Pages/BlogUtilityNftDetails';
import BlogWeb3Biz from './components/Pages/BlogWeb3Biz';
import BlogAIBlockchain from './components/Pages/BlogAIBlockchain';
import BlogWeb3Roles from './components/Pages/BlogWeb3Roles';
import BlogDataDetails from './components/Pages/BlogDataDetails';
import TechDetailsBlockchain from './components/Pages/TechDetailsBlockchain';
import TechDetailsDapp from './components/Pages/TechDetailsDapp';
import TechDetailsExp from './components/Pages/TechDetailsExp';
import TechDetailsWeb3 from './components/Pages/TechDetailsWeb3';
import Layout from './components/Layout';
import FaqPage from './components/Pages/FaqPage';
import Litepaper from './components/Pages/Litepaper';


function App() {


  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          

          <Route path="about" element={<AboutPage />} />
          <Route path="tech" element={<TechPage />} />
          <Route path="tech/d-tixs" element={<TechDetailsDapp />} />
          <Route path="tech/streamillonaire" element={<TechDetailsWeb3/>} />
          <Route path="tech/alujo" element={<TechDetailsExp />} />
          <Route path="tech/abiinchii" element={<TechDetailsBlockchain/>} />
          
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="portfolio/streamillonaire" element={<PortfolioStreamillonaire />} />
          <Route path="portfolio/bubbleboy" element={<PortfolioBubbleboy />} />
          <Route path="portfolio/creative" element={<PortfolioCreative />} />
          <Route path="portfolio/galacticos" element={<PortfolioGalacticos />} />
          <Route path="portfolio/loverrr" element={<PortfolioLoverrr />} />
          <Route path="portfolio/pasuma" element={<PortfolioPasuma />} />
          <Route path="portfolio/baggies" element={<PortfolioMylarBaggies />} />

          <Route path="blog" element={<BlogPage />} />
          <Route path="/blog/a-case-for-nfts" element={<BlogNFTDetails/>} />
          <Route path="/blog/how-not-to-start-a-web3-biz" element={<BlogHowNot/>} />
          <Route path="/blog/a-case-for-data-engineers" element={<BlogDataDetails/>} />
          <Route path="/blog/important-roles-in-web3" element={<BlogWeb3Roles />} />
          <Route path="/blog/utility-based-nfts" element={<BlogUtilityNftDetails/>} />
          <Route path="/blog/ai-blockchain-revolution" element={<BlogAIBlockchain/>} />
          <Route path="/blog/important-roles-in-web3" element={<BlogWeb3Biz/>}/>
          

          <Route path="contact" element={<ContactPage />} />
          <Route path="team" element={<TeamPage />} />

          <Route
            path="/litepaper"
            element={<Litepaper />}
          />
          <Route path="faq" element={<FaqPage />} />
        </Route>

        

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
