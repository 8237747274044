import React, { useEffect } from 'react'
// import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import VideoModal from '../VideoModal'

export default function TechDetailsDapp() {
  pageTitle('Decentralized Applications (DApp)');
  // const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading
        title='d (DApp) Tickets'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Event Ticketing'
      />
      <Spacing lg='145' md='80' />
      <Div className="container">
        <SectionHeading
          title='d (DApp) Tickets'
          subtitle='Decentralized Ticketing'
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45' />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Design'
              subtitle="The first pillar in our conception. We recognize that design isn't just about aesthetics; it's about creating experiences that leave lasting impressions.."
            />
            <Spacing lg='30' md='30' />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Deploy'
              subtitle="Each launch of smart contracts or projects, isn't just about pushing code—we're shaping the future. Each deployment marks a milestone, streamlining processes and propelling our communities towards success."
            />
            <Spacing lg='30' md='30' />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Scale'
              subtitle="While we're building for today, we reserve growth and scalability into each smart contract or project to ensure competitiveness and resilience to the ever changing world of development."
            />
            <Spacing lg='30' md='30' />
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50' />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <VideoModal
                videoSrc="https://www.youtube.com/watch?v=UhPmvIyJb5w"
                bgUrl="/images/dapp.png"
              />
            </Div>
            <Spacing lg='0' md='40' />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Key features of d-tickets dApp</h2>
            <Spacing lg='50' md='30' />
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='/tech/d-tixs' btnText='NFT-as-a-Service' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/tech/d-tixs' btnText='DApp Architecture' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/tech/d-tixs' btnText='Card payments' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/tech/d-tixs' btnText='Pay-by-Crypto' variant='cs-type2' />
                <Spacing lg='0' md='10' />

              </Div>
              <Div className="col-lg-6">
                <Button btnLink='/tech/d-tixs' btnText='Web3 Data Warehouse' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/tech/d-tixs' btnText='Off-chain CRM Management' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/tech/d-tixs' btnText='Blockchain CRUD implementation' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/tech/d-tixs' btnText='Web 3 API' variant='cs-type2' />
                <Spacing lg='20' md='10' />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <TestimonialSlider />
      <Spacing lg='145' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers'
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title="Learn about our <br /><i>exciting</i> projects"
          btnText='Contact Us Today' 
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
