import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
//import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';

export default function Home() {
  pageTitle('Home');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'LinkedIn',
      links: 'https://linkedin.com/company/lagos-labs',
    },
    {
      name: 'Instagram',
      links: 'https://www.instagram.com/lagoslabs',
    },
    {
      name: 'Twitter',
      links: 'https://www.twitter.com/lagoslabs',
    },
    {
      name: 'Github',
      links: 'https://github.com/LagosLabs',
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Average Monthly Users',
      factNumber: '100K',
    },
    {
      title: 'Fulfilled Daily Transactions',
      factNumber: '1M',
    },
    {
      title: 'Countries by 2030',
      factNumber: '100',
    },
    {
      title: 'Revenue Split for Participation',
      factNumber: '$10M',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="Design, <br/> Deploy, <br/> Scale."
        subtitle="At Lagos Labs, We're Harnessing Blockchain, Decentralization and Open Source Technologies to Build Blockchain-as-a-Service Business Solutions."
        btnLink="/about"
        scrollDownId="#tech"
        socialLinksHeading="Follow Us"
        heroSocialLinks= {heroSocialLinks}
        bgImageUrl="/images/eko_bridge.gif"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Our Vision"
          subtitle="To build Web 3 applications for daily use focused on the user experience."
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Tech Section */}
      <Spacing lg="150" md="80" />
      <Div id="tech">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="How we're driving change"
                subtitle="What The Tech?"
                btnText="See more"
                btnLink="/tech"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="dApp Tixs"
                    link="/tech/d-tixs"
                    src="/images/d_tickets.png"
                    alt="Tech"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Alujo"
                    link="/tech/alujo"
                    src="/images/alujo.png"
                    alt="Tech"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Stmmy"
                    link="/tech/streamillonaire"
                    src="/images/stmmy.png"
                    alt="Tech"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Abiinchii"
                    link="/tech/abiinchii"
                    src="/images/abiinchii.png"
                    alt="Tech"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start Portfolio Section */}
      <Spacing lg="150" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Explore some of our Inspiration"
            subtitle="Inspired Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
      </Div>
      {/* End Portfolio Section */}

      {/* Start Awards Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="We get multiple awards"
                subtitle="Our Awards"
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Awards Section */}

      {/* Start Video Block Section */}
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Our dApp-centric Software Product Line enables faster go-to-market products focused on user experience and empowerment.
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=UhPmvIyJb5w"
          bgUrl="/images/portfolio_details_2.jpg"
        />
      </Div>
      {/* End Video Block Section */}

      {/* Start Team Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Meet the <br/> Founders"
          subtitle="The Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End Team Section */}

      {/* Start Testimonial Section */}
      <TestimonialSlider />
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Learn More "
                subtitle="Media & Education"
                btnText="View More Publications"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Blog Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Our Global Partners and Collaborators" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Learn about our <br /><i>exciting</i> projects"
          btnText="Contact Us Today"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
