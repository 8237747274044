import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import 'swiper/css';
import 'swiper/css/pagination';
import Hero6 from '../Hero/Hero6';
import Spacing from '../Spacing';
import Div from '../Div';
import Button from '../Button';
import SectionHeading from '../SectionHeading';

export default function PortfolioCreative() {
  pageTitle('Creative After Dark');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const showcaseData = [
    {
      title: '',
      imgUrl: '/images/creative_2.png',
      href: '',
    },
    {
      title: '',
      imgUrl: '/images/creative_3.png',
      href: '',
    },
    {
      title: '',
      imgUrl: '/images/creative_4.png',
      href: '',
    },
    {
      title: '',
      imgUrl: '/images/creative_5.png',
      href: '',
    },
  ];

  return (
    <>
      <Hero6
        showcaseData={showcaseData}
      />
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Spacing lg='90' md='40'/>
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading 
              title="Fan Engagement"
              subtitle= "Creative After Dark"
            >
              <Spacing lg='40' md='20'/>
              <p>A fashion show that bridges the physical and digital worlds. We partnered with Wolve Production and the MoS brand to deliver a fan experience and customer engagement by including NFTs in the runway.</p>
              <Spacing lg='10' md='10'/>
              <p>In addition, we provided the portal for guests to tryout digital fashion on-site and on the web...</p>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg='60' md='40'/>
            <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Project Info -</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Category:</h3>
                <p className='cs-m0'>Fan Engagement</p> 
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Location:</h3>
                <p className='cs-m0'>WNDR Museum, Chicago</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Dated:</h3>
                <p className='cs-m0'>03-Jun-2023</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Client:</h3>
                <p className='cs-m0'>MoS, Wolve Productions</p>
                <Spacing lg='30' md='30'/>
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg='65' md='10'/>
          <Div className="cs-page_navigation cs-center">
            <Div>
              <Button btnLink='/portfolio/streamillonaire' btnText='Prev Project' variant='cs-type1'/> 
            </Div>
            <Div>
              <Button btnLink='/portfolio/baggies' btnText='Next Project'/> 
            </Div>
          </Div>
      </Div>
    </>
  );
}
