import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import 'swiper/css';
import 'swiper/css/pagination';
import Hero6 from '../Hero/Hero6';
import Spacing from '../Spacing';
import Div from '../Div';
import Button from '../Button';
import SectionHeading from '../SectionHeading';



export default function PortfolioLoverrr() {
  pageTitle('Loverrr International');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const showcaseData = [
    {
      title: '',
      imgUrl: '/images/loverrr_1.png',
      href: '',
    },
    {
      title: '',
      imgUrl: '/images/loverrr_2.png',
      href: '',
    },
    {
      title: '',
      imgUrl: '/images/loverrr_3.png',
      href: '',
    },
    {
      title: '',
      imgUrl: '/images/loverrr_4.png',
      href: '',
    },
  ];

  return (
    <>
      <Hero6
        showcaseData={showcaseData}
      />
       <Spacing lg='150' md='80'/>
      <Div className='container'>
        <Spacing lg='90' md='40'/>
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading 
              title="Fan Engagement"
              subtitle= "Loverrr International"
            >
              <Spacing lg='40' md='20'/>
              <p>As an inspiration, we stream the live event to a global audience, and fans IRL and in attendance at selected venues. </p>
              <Spacing lg='10' md='10'/>
              <p>As an event management team, the Loverrr brand is a key player in our iteration and design of the d(App) event ticketing DApp.</p>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg='60' md='40'/>
            <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Project Info -</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Category:</h3>
                <p className='cs-m0'>Fan Experience</p> 
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Location:</h3>
                <p className='cs-m0'>Worldwide</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Dated:</h3>
                <p className='cs-m0'>16-Feb-2024</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Client:</h3>
                <p className='cs-m0'>Loverrr Intl., SOHO House Chicago</p>
                <Spacing lg='30' md='30'/>
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg='65' md='10'/>
          <Div className="cs-page_navigation cs-center">
            <Div>
              <Button btnLink='/portfolio/baggies' btnText='Prev Project' variant='cs-type1'/> 
            </Div>
            <Div>
              <Button btnLink='/portfolio/pasuma' btnText='Next Project'/>
            </Div>
          </Div>
      </Div>
    </>
  );
}